<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
        <Toast />
      <Panel header="Configuracion Correos Automaticos">
              <BasicFormToolbar @new="openNew" @refresh="refresh" :actions="['new', 'refresh']" />
        <BasicDatatable :rowedit="true" :rowaction="true" :rowdelete="true" :headers="headers" :rows="entities" @edited="edit" @deleted="confirmDelete" />
        <Dialog
          v-model:visible="newDialog"
          :style="{ width: '750px' }"
          :header="!entity.id ? 'Nueva Configuracion' : 'Editar Configuracion'"
          :modal="true"
          class="p-fluid"
        >
        <div class="grid formgrid">
          <FormDropdown  wrapperClass="field col-12" label="Clave" v-model="entity.key_name" :valid="validate.validations.key_name" :options="configurations" :optionLabel="'id'" :optionValue="'id'" />
          <FormInputText wrapperClass="field col-6" label="E-mail" v-model="entity.email_address" :valid="validate.validations.email_address"  />
          <FormInputText wrapperClass="field col-6" :type="'password'" label="Password" v-model="entity.email_password" :valid="validate.validations.email_password"  />
          <FormDropdown wrapperClass="field col-4" :tooltip="'Si se necesita agregar un host nuevo, contactar con area de sistemas'" label="Host" v-model="entity.email_host" :valid="validate.validations.email_host" 
          :options="entity.type == 'SENDER' ? sender_hosts : receiver_hosts" :optionLabel="'id'" :optionValue="'id'"/>
          <FormInputText wrapperClass="field col-4" label="Puerto" v-model="entity.email_port" :valid="validate.validations.email_port"  />
          <FormDropdown wrapperClass="field col-4" label="Tipo Encriptacion" v-model="entity.encryption_type" :options="[{id: 'NONE'},{id: 'SSL'}, {id: 'TLS'}, {id: 'STARTTLS'}]" :optionLabel="'id'" :optionValue="'id'" :valid="validate.validations.encryption_type"  />
          <FormDropdown :disabled="true" wrapperClass="field col-12" label="Tipo" v-model="entity.type" :options="[{id: 'RECEIVER'},{id: 'SENDER'}]" :optionLabel="'id'" :optionValue="'id'" :valid="validate.validations.type"  />
          <FormInputText v-if="entity.type == 'SENDER'" wrapperClass="field col-6" label="Titulo" v-model="entity.sender_title" />
          <FormInputText v-if="entity.type == 'SENDER'" wrapperClass="field col-6" label="CC" v-model="entity.sender_cc"   />
        </div>           
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              :loading="loading"
              @click="save"
            />
          </template>
        </Dialog>
        <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" /> 
      </Panel>  
    </div>
  </div>
</template>

<script>
import { EmailConfiguration } from "../../../models/general/EmailConfiguration";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import { EMAIL_CONFIGURATIONS } from "../../../utilities/EMAIL_CONFIGURATIONS";
export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      entities: [],
      //* URL para subir el importador
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      configurations: EMAIL_CONFIGURATIONS,
      sender_hosts: [
        { id: "smtp.gmail.com" }
      ],
      receiver_hosts: [
        { id: "imap.gmail.com" }
      ],
      rules: [
        new Rule({ name: "key_name"}),
        new Rule({ name: "email_password"}),
        new Rule({ name: "email_address"}),
        new Rule({ name: "email_host"}),
        new Rule({ name: "email_port"}),
        new Rule({ name: "encryption_type"})
      ],
      validate: {
        valid: false,
        validations: {
          key_name: null,
          email_password: null,
          email_address: null,
          email_host: null,
          email_port: null,
          encryption_type: null
        },
      },
      //* Headers donde pondremos el nombre y el valor del datatable
      headers: [
        new HeaderGrid("Clave", "key_name")
      ],
      loading: false,
    };
  },
  components: { FormDropdown,FormInputText, Loader, BasicFormToolbar, BasicDatatable, ConfirmDeleteDialog },
  created() {
    console.log(this.session);
    this.entity = new EmailConfiguration(this.session);
  },
  async mounted() {
    await this.refresh();
  },
  watch: {
    'entity.type'(newValue) {
      if (newValue) {
        this.entity.email_host = null;
      }
    },
    'entity.key_name'(newValue) {
      if (newValue) {
        this.entity.type = this.configurations.find(x => x.id == newValue).type;
      }
    }
  },
  methods: {
    openNew() {
      this.entity = new EmailConfiguration(this.session);
      this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra

          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new EmailConfiguration(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new EmailConfiguration(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
